import revive_payload_client_wiL1D3uWyN from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__63ldy6figtg6iooxz3slrdos5q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_9XRS2aYlos from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__63ldy6figtg6iooxz3slrdos5q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qJCz6uI7wN from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__63ldy6figtg6iooxz3slrdos5q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_1xq4y0CMSJ from "/app/node_modules/.pnpm/nuxt-site-config@2.2.18_magicast@0.3.5_rollup@4.22.4_vite@5.4.8_@types+node@22.7.0_sass@1.77._wrg4eqw2n4x7xicyrzp6aqquqq/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_VJaTziLhDu from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__63ldy6figtg6iooxz3slrdos5q/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_kkkuvkxcDU from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__63ldy6figtg6iooxz3slrdos5q/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_e1IEIj3D0p from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__63ldy6figtg6iooxz3slrdos5q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_OPJd3zwJ3t from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.5_rollup@4.22.4_typescript@5.6.2_vue@3.5.8_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/packages/kiwi-user/.nuxt/components.plugin.mjs";
import prefetch_client_oKIooxqvp6 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.0_eslint@9.12.0_jiti@2.4.2__ioredis@5.4.1__63ldy6figtg6iooxz3slrdos5q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_VwpDGs0LLa from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.9.2_magicast@0.3.5_next-auth@4.21.1_next@13.5.7_@babel+core@7.25.2_reac_mlm2ncqb77edy6d2jbode5ngiq/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import siteConfig_ZHh5SSSnve from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.6_magicast@0.3.5_rollup@4.22.4_vite@5.4.8_@types+node@22.7.0_sa_g33ny4e65p7pkdwfmtoxksjc3y/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_s7lyTBtHhu from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.6_magicast@0.3.5_rollup@4.22.4_vite@5.4.8_@types+node@22.7.0_sa_g33ny4e65p7pkdwfmtoxksjc3y/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_client_LyqjJcraqI from "/app/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.22.4/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import rollbar_XnPIYyOzed from "/app/packages/kiwi-user/plugins/rollbar.ts";
export default [
  revive_payload_client_wiL1D3uWyN,
  unhead_9XRS2aYlos,
  router_qJCz6uI7wN,
  _0_siteConfig_1xq4y0CMSJ,
  payload_client_VJaTziLhDu,
  navigation_repaint_client_kkkuvkxcDU,
  chunk_reload_client_e1IEIj3D0p,
  plugin_vue3_OPJd3zwJ3t,
  components_plugin_KR1HBZs4kY,
  prefetch_client_oKIooxqvp6,
  plugin_VwpDGs0LLa,
  siteConfig_ZHh5SSSnve,
  inferSeoMetaPlugin_s7lyTBtHhu,
  plugin_client_LyqjJcraqI,
  rollbar_XnPIYyOzed
]